import React, { useState } from 'react';
import ImageGrid from './images/ImageGrid';

const ImageUpload = ({ imageList, setImageList, originalImagesNumber }) => {

  const oIN = originalImagesNumber.current;
  const moveImage = (index, direction) => {
    const newImageList = [...imageList];
    if (index + direction >= 0 && index + direction < newImageList.length) {
      [newImageList[index], newImageList[index + direction]] = [
        newImageList[index + direction],
        newImageList[index],
      ];
      setImageList(newImageList);
    }
  };

  const handleFiles = (files, index) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageList = [...imageList];
        newImageList[index] = e.target.result;
        setImageList(newImageList);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e, index) => {
    handleFiles(e.target.files, index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files, index);
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDeleteImage = (index) => {
    const newImageList = [...imageList];
    newImageList.splice(index, 1);
    setImageList(newImageList);
  };
  return (
    <div id="imageSection" className="mt-6">
      <label htmlFor="new_image_input" className="block text-lg font-medium text-gray-800 mb-2">Images</label>
      <ImageGrid
        imageList={imageList}
        oIN={oIN}
        moveImage={moveImage}
        handleFileInputChange={handleFileInputChange}
        handleDeleteImage={handleDeleteImage}
        handleDrop={handleDrop}
        preventDefaults={preventDefaults}
      />
    </div>
  );
};

export default ImageUpload;
