import React, {useState} from 'react';
import {FaSpinner, FaImage} from 'react-icons/fa';

const ImageProcessor = () => {
    const data = {
        original_image: 'https://res.cloudinary.com/instantum/image/upload/v1730398173/Unbox%20Me/81dtTfUY-NL._SL1500__pcg17h.jpg',
        recommendation_images: [
            'https://res.cloudinary.com/instantum/image/upload/v1730398176/Unbox%20Me/1.02-1x1-Moshe_Scheiner-RG-86dv0kd8f_s6zj3g.png',
            "https://res.cloudinary.com/instantum/image/upload/v1730398175/Unbox%20Me/2.01-1x1-Moshe_Scheiner-RG-86dv0kd8f_a1ed33.png"

        ]
    };

    const [isProcessing, setIsProcessing] = useState(false);
    const [showRecommendations, setShowRecommendations] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleProcessClick = () => {
        setIsProcessing(true);
        setTimeout(() => {
            setIsProcessing(false);
            setShowRecommendations(true);
        }, 5000);
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
    };

    return (
        <div className="flex flex-col items-center p-6 space-y-6 bg-gray-50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-700">Image Processor</h2>

            {/* Original Image */}
            <div className="w-full h-auto max-w-md">
                <img
                    src={data.original_image}
                    alt="Original"
                    className="object-cover w-full  rounded-md shadow-md"
                />
            </div>

            {/* Process Button */}
            <button
                onClick={handleProcessClick}
                disabled={isProcessing}
                className={`flex items-center px-6 py-2 font-semibold rounded-md border border-solid  border-blue-500 text-blue-500  
                    hover:border-blue-600 hover:text-blue-600 disabled:bg-gray-400 transition duration-200 ease-in-out transform ${
                    isProcessing ? 'opacity-80' : 'hover:scale-105'
                }`}
            >
                {isProcessing ? <FaSpinner className="mr-2 animate-spin"/> : <FaImage className="mr-2"/>}
                {isProcessing ? "Processing..." : "Process"}
            </button>

            {/* Processing Message */}
            {isProcessing && (
                <div className="text-center text-lg text-gray-600">
                    Processing image with title and bullet points of the product...
                </div>
            )}

            {/* Recommendations */}
            {showRecommendations && (
                <div className="space-y-4 w-full">
                    <h3 className="text-2xl font-semibold text-gray-700 mt-6 text-center">Select an Image to Proceed</h3>
                    <div className="flex justify-center gap-8 mb-8">
                        {data.recommendation_images.map((image, index) => (
                            <div
                                key={index}
                                className={`relative w-40 h-40 md:w-80 md:h-80 rounded-lg overflow-hidden shadow-md group cursor-pointer border-4 transition-all duration-300 
        ${selectedImage === image ? 'border-blue-500 shadow-xl ' : 'border-transparent shadow-sm'}`}
                                onClick={() => handleImageSelect(image)}
                            >
                                <img
                                    src={image}
                                    alt={`Recommendation ${index + 1}`}
                                    className="object-cover w-full h-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                                />
                                <div
                                    className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                                >
                                    <span className="text-white text-lg font-semibold">Recommendation {index + 1}</span>
                                </div>
                            </div>

                        ))}
                    </div>

                    {/* Test Button, only shown when an image is selected */}
                    {selectedImage && (
                        <button
                            className="mt-20 mx-auto flex items-center px-8 py-2 border border-solid  border-blue-500 text-blue-500
                    hover:border-blue-600 hover:text-blue-600 font-semibold rounded-md transition duration-200 ease-in-out transform hover:scale-105 "
                        >
                            Test the Image
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImageProcessor;
