import TableRow from "./TableRow";
import React from "react";

function ProductInfoTable({product, startDate, endDate, type}) {
    return (
        <div className="p-4 border-t border-gray-100">
            <div className="overflow-x-auto">
                <table className="min-w-full table-auto text-left border-collapse">
                    <tbody className="divide-y divide-gray-200">
                    <TableRow label="SKU" value={product.seller_sku}/>
                    <TableRow label="ASIN" value={product.asin1}/>
                    <TableRow label="Start Date" value={new Date(startDate).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    })}/>
                    <TableRow label="End Date" value={new Date(endDate).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    })}/>
                    <TableRow label="Test Type" value={type.charAt(0).toUpperCase() + type.slice(1)}/>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default ProductInfoTable;
