import React from 'react';
import {FaCloudUploadAlt} from 'react-icons/fa';

const UploadImage = ({index, handleDrop, preventDefaults, handleFileInputChange}) => {
    return (
        <div
            className="border-2 border-dotted border-gray-400 rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer hover:bg-gray-100 transition w-64 h-64"
            onDrop={(e) => handleDrop(e, index)}
            onDragEnter={preventDefaults}
            onDragOver={preventDefaults}
        >
            <label className="text-blue-500 hover:text-blue-600 font-medium cursor-pointer text-center">
                <FaCloudUploadAlt size={30} className="mx-auto"/> <br/>
                Click to upload or drag and drop
                <input
                    type="file"
                    name="new_image"
                    className="hidden"
                    onChange={(e) => handleFileInputChange(e, index)}
                />
            </label>
            <span className="mt-3 text-gray-500">No file chosen</span>
        </div>
    );
};

export default UploadImage;
