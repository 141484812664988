import React from 'react';

const FamilyResults = ({ variants }) => {
  return (
    <div id="family-content" className="tab-content">
      <div className="p-6 bg-white shadow rounded-lg">
        <p className="text-xl font-semibold text-gray-700 mb-2">Family Results</p>
        <p className="text-sm text-gray-500">Results of the test on other variants.</p>

        {/* Summary of Family Results */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {/* Total Orders Box */}
          <MetricBox
            title="Total Orders"
            value={variants.total_orders}
            icon="shopping-cart"
            color="green"
          />
          {/* Total Sales Box */}
          <MetricBox
            title="Total Sales"
            value={`$${variants.total_sales}`}
            icon="cash"
            color="blue"
          />
          {/* Total Page Views Box */}
          <MetricBox
            title="Total Page Views"
            value={variants.total_page_views}
            icon="eye"
            color="orange"
          />
        </div>

        {variants.grouped_results && variants.grouped_results.length > 0 ? (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
            {/* Header Row */}
            <div className="flex bg-gray-50 p-2 font-medium text-gray-500 uppercase tracking-wider text-xs">
              <div className="w-9/12">Product</div>
              <div className="w-1/12 text-center">Orders</div>
              <div className="w-1/12 text-center">Page Views</div>
              <div className="w-1/12 text-center">Total Sales</div>
            </div>

            {variants.grouped_results.map((dateGroup, index) => (
              <div key={index}>
                {/* Date Row */}
                <p className="font-semibold text-gray-800 mb-4 text-center border-t border-gray-200 pt-2">
                  Date: {dateGroup.date}
                </p>

                {dateGroup.sales_data.map((sale, saleIndex) => (
                  <div key={saleIndex} className="flex p-2 border-b border-gray-200 items-start">
                    <div className="w-9/12 text-sm font-medium text-gray-900">
                      <p className="truncate">{sale.product}</p>
                      <p className="text-xs text-gray-600">ASIN: {sale.asin}</p>
                    </div>
                    <div className="w-1/12 text-center text-sm text-gray-500">{sale.orders}</div>
                    <div className="w-1/12 text-center text-sm text-gray-500">{sale.page_views}</div>
                    <div className="w-1/12 text-center text-sm text-gray-500">${sale.product_sales}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <p>No family sales data available for the selected period.</p>
        )}
      </div>
    </div>
  );
};

const MetricBox = ({ title, value, icon, color }) => (
  <div className="bg-white shadow rounded-lg p-6">
    <div className="flex items-center space-x-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="48"
        height="48"
        className={`text-${color}-600`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5H2M7 13l-1.5 6M17 13l1.5 6M9 21h6" />
      </svg>
      <div>
        <p className="text-2xl font-bold">{value}</p>
        <p className="text-sm text-gray-500">{title}</p>
      </div>
    </div>
  </div>
);

export default FamilyResults;
