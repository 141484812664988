import React from 'react';
import {FaGift, FaRegEdit, FaRegCheckCircle, FaUsers} from 'react-icons/fa';
import {Link} from "react-router-dom";

const data = {
    'keywords': ['Gift Box For Women', 'Appreciation Gift for women', 'Care Package'],
    'current_title': "Unboxme Whiskey Gift Box For Men - Includes A Custom Card With Your Message | Gift Basket For Him With Whiskey Glass & Beef Jerky | The Perfect Birthday, Retirement, Anniversary, Father's Day Gift",
    'current_bullet_points': [
        "Custom Card With Your Personal Message: Add the perfect personal touch - each gift includes a greeting card of your choice custom printed with your message! Only available on select boxes.",
        "Whiskey Glass: The whiskey wedge is the artful way to perfectly chill your favorite spirits. It's the ideal whiskey glass for anyone who enjoys smooth sipping. During freezing, the ice forms a wedge shape on one side of the glass. When you pour a drink, the wedge melts slowly to help retain your drink's full flavor.",
        "Charred Oak & Maple Cocktail Syrup: Perfectly crafted to add a toasty, oaky complexity to your old fashioned or any classic cocktail.",
        "Bourbon Franklin Beef Jerky: Beef Jerky with a twist. Bourbon, a pinch of vanilla bean, and a whisper of cinnamon.",
        "Orange Crisps: Perfect for infusing your cocktails with a bit of extra flavor or for snacking, these dried California orange crisps are just the right amount of sweet with no added sugars.",
    ],
    'competitors': [
        {
            'title': "Gift Baskets for Women, 10pcs Unique Birthday Gifts for Women, Best Friend Birthday Gifts, Get Well Soon Gift Basket Sending Sunshine Gifts Cheer Up Box for Sister Friendship - BRIGHTEN UP HER DAY!",
            'image': 'https://m.media-amazon.com/images/I/71lR8a-pISL._AC_SX679_.jpg',
            'bullet_points': [
                "【THIS WILL BE A HIT】Brighten up somebody's day - LITERALLY! Presenting our awesome little yellow pick-me-up box filled with sunshine and happiness, We use the \"A box of sunshine\" to convey positive energy or celebrate happy birthdays. When your loved ones receive it, they will definitely smile brightly",
                "【SENDING SUNSHINE】Our sunshine care package are filled with bright, colorful items that that are fun yet also high quality, including: (1)20 oz Insulated Wine Tumbler | (2)Silk Eye Mask | (3)Fuzzy Socks | (4)Sunshine Stress Ball | (5)Elastic Hair Scrunchy | (6)Sunflower Inspirational Keychain | (7)7oz Blue Wind Chime Scented Candle | (8)Bath Bomb | (9)Oatmeal Soap Bar | (10)Sending Sunshine Card",
                "【THE \"I'M THINKING OF YOU\" GIFT】Whether it’s as simple as celebrating a happy birthday, or as exciting as commemorating a big life event, or maybe she is going through a tough time etc., We all seriously say that our sunshine boxes are the best gift you can give. Perfect as: birthday gifts, get well soon gifts, after surgery recovery gifts, thinking of you gifts, cheer up gifts, self care gifts, Mother's day gifts, Valentine's day gifts, Christmas gifts",
                "【GIFTS FOR WOMEN WHO HAVE EVERYTHING】Send a box of sunshine to your loved one! They deserve the best. A unique birthday gifts for women ideas(get well soon gift basket ideas) for any female including best friend, mom, sister, daughter, coworker, girlfriend or any women you care about",
                "【PLEASE FOCUS HERE! 】Who doesn’t love to receive an unexpected unique gifts? Inspirational, positive, funny, bright, cheerful, citrusy and sunny all in one gift baskets for women. We are confident this is a perfect gift for women! Undoubtedly, this will be the most shining and practical gifts at a birthday party or Christmas party. NOTE: PLEASE ADD YOUR PERSONALIZED MESSAGE BY CHECKING - This order contains a gift"
            ],
            'url': "https://www.amazon.com/Baskets-Birthday-Sending-Sunshine-Friendship/dp/B0CGLRZG9M/ref=sr_1_2?sr=8-2"

        },
        {
            'title': "Bath and Body Gift Basket For Women and Men – 9 Piece Set of Vanilla Coconut Home Spa Set, Includes Fragrant Lotions, Extra Large Bath Bombs, Coconut Oil, Luxurious Bath Towel & More",
            'image': 'https://m.media-amazon.com/images/I/81-sr5Zp-cL._SX466_.jpg',
            'bullet_points': [
                "DELUXE 9 PIECE SET ♥ This spa gift basket has everything a woman needs! It is the perfect Birthday Gifts for Women. It includes Shower Gel, Bubble Bath, Body Scrub, Body Oil, 2 Large Bath Bombs, Bath Salt and a luxurious Bath Towel. Packed in a stunningly wrapped in a handmade basket, it also has a gift note card for you to personalize it.",
                "ENTICING VANILLA ♥ Close your eyes, inhale deeply and transport yourself into a world of luscious beauty! Who can resist the tantalizing notes of vanilla as they sooth, relax and invigorate your body and spirit? Nothing beats the rich and vibrant fragrance of vanilla. Let your sense be enveloped by its charming embrace.",
                "FRAGRANT & FORTIFYING COCONUT OIL ♥ Everyone knows that coconut has so many benefits for your skin and body, the list is far too lengthy to detail. When paired with luscious vanilla, this tropical scent creates a delightful aroma that not only charms your senses, but helps revitalize and rejuvenate your skin.",
                "PREMIUM QUALITY INGREDIENTS ♥ Every Lovery product is 100% Paraben and Cruelty-Free. These enriching beauty products are made with moisturizing Shea Butter and nourishing Vitamin E. They are safe even on sensitive skin! We’re confident you’ll fall in love with our beauty set, we’ve backed it with a 100% money back satisfaction guarantee.",
                "PERFECT FOR HER ♥ It's the present any girl will love! Pamper and indulge the special lady in your life with a stunningly beautiful and elegant gift package. It makes a wonderful present for Birthdays, Holidays, Christmas, Anniversaries, Mother’s Day and Valentine’s Day. Bring a smile to your Girlfriend, Wife, Mother, Daughter or Beloved Ones. It is the ultimate gifts for women and men."
            ],
            'url': "https://www.amazon.com/Valentines-Bath-Body-Basket-Women/dp/B07GBFT6Z3/ref=sr_1_3?sr=8-3"
        },
        {
            'title': "Birthday Gifts for Women, Gifts for Women Mom, Wife, Girlfriend, Sister, Friends, Her, Relaxing Spa Gifts Basket Self Care Gifts For Women Christmas, Valentine's Day, Mother's Day Gifts for Women",
            'image': 'https://m.media-amazon.com/images/I/81W7n9gRe4L._SX466_.jpg',
            'bullet_points': [
                "Birthday Gifts For Women: Are you still struggling to choose a wonderful gifts? Come and take a look at this birthday gifts for women that we have exquisitely designed for you! This one-of-a-kind birthday gift box is sure to surprise her sweetly. Our birthday gift box is designed to make the women, bestie, bff, best friend, coworker from sisters, friends, wife, girlfriend, daughter or teacher, nurse, who receives it feel loved and to make it easy for you to choose a gift that is versatile.",
                "Gifts For Women: Surprise her with this heartwarming gifts that's sure to bring a smile to her face, there's something for every woman. This unique birthday gifts for women is the ultimate way to show your appreciation and affection. Perfect for any occasion on her birthday, mother's day, Anniversary Day, Valentines day gifts, Thanksgiving, Retirement or Christmas gifts for her. It's the ideal present for any woman in your life.",
                "Spa Gifts For Women: Our birthday spa gifts baskets can help her relax body and mind. The aromatherapy candle fills the room with a soothing fragrance, while the handmade soap and bath bomb offer a spa-like experience in the comfort of one's own home, she could pamper and indulge herself with a deluxe bath salts while enjoying her favorite drink for the special occasion in the fresh fragrance atmosphere. Making this gift box practical as well as luxurious.",
                "Perfect Gifts Idea For Women: Every item in our gift box is carefully selected including: 1*20oz wine tumbler, 1*bath salts, 1* 3.5oz lavender scented candle, 1*hand cream, 1*diamond handmade soap, 1* natural stone bracelet, 1*bath bomb, 1*bath loofahs 1*gift box, 1*greeting card. A best wishes greeting card is included to write your blessing message freely give your loved ones an unparalleled experience.",
                "Gifts Message Ready: Simply add a personalized message (at checkout) and it will be attached to your gifts. You may also ship the gift box directly to the recipient for added convenience! If there are any problems after receiving the relaxation gifts set, please feel free to contact us and we will give you a satisfactory reply within 24 hours."
            ],
            'url': "https://www.amazon.com/Birthday-Relaxing-Girlfriend-Coworker-Relaxation/dp/B0BW9LW5BF/ref=sr_1_4?sr=8-4"
        },
    ],
    'recommendations': {
        'title': 'Unboxme Deluxe Birthday Care Package for Women: Deluxe Birthday Gift Set for Her - Cozy Socks, Tea, Scented Candle, Mug & Heartfelt Card',
        'bullet_points': [
            "Send a warm hug in a box to show your support and brighten someone's day, even from afar. Unboxme's get well soon gifts for women are meticulously packed with premium items that bring love and comfort.",
            "Complete Care Package: Thoughtful gifts for anyone needing a little comfort. Includes fluffy pink socks, a sea salt + sage soy candle, lavender & vanilla honey, a herbal tea set, a cute faux succulent, and a 'Happy Birthday' card.",
            "Versatile Gift Box: These wellness gift baskets serve as perfect get well soon gifts, sick care packages, birthday surprises for friends, Mother's Day gifts, sympathy baskets, or even delightful tea gift sets.",
            "Ready to Send: No wrapping required! Unboxme self-care gifts come ready to be sent directly to the recipient. Each care package includes a heartfelt greeting card to express your love and support.",
            "Support Small Businesses: Unboxme's feel better gifts are curated with high-quality artisan products from small, family-owned US businesses, empowering women entrepreneurs with every purchase.",
            "Ultimate Birthday Pampering: Enjoy the Unboxme Deluxe Birthday Care Package—an ideal happy birthday box for women featuring cozy fluffy socks, an adorable mug, soothing lemon & ginger tea set, aromatic scented candle, and a heartfelt greeting card.",
            "Thoughtfully Curated: Every item in this women's birthday gift set is handpicked for maximum joy. From the cheerful presentation to the unique selection of items, it's a birthday gift basket that radiates warmth and thoughtfulness.",
            "Gift-Ready: Save time with this beautifully packaged birthday gift set for women. The Unboxme care package arrives in a festive happy birthday box, making it an instant gift that's delightful to give and receive.",
            "Personalized Touch: Each women's birthday gift set includes a blank greeting card for your personal message, adding a special touch to the happy birthday gifts for women.",
            "Versatile Choice: Whether you're searching for birthday boxes for mom, presents for friends, or gifts for any woman in your life, the Unboxme Deluxe Birthday Care Package is an all-in-one solution that will surely brighten her day."
        ]
    }
};

const CompetitorCard = ({title, image, bulletPoints, url, index}) => (
    <div
        className="flex-1 p-4 bg-white rounded-lg shadow-lg flex flex-col items-center hover:shadow-xl transition-shadow duration-200">
        <h3 className="text-md font-semibold text-gray-800 mb-3 text-center px-2">{title}</h3>
        <img
            src={image}
            alt={title}
            className="mb-4 w-48 h-48 object-cover rounded-md shadow-md"
        />
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-auto w-full text-center px-4 py-2 border border-solid border-blue-400 text-blue-400 rounded-lg shadow hover:border-blue-600 hover:text-blue-600 transition-colors"
        >
            View Product
        </a>
    </div>
);

const Recommendations = ({productId}) => {
    const {keywords, recommendations, competitors} = data;

    return (
        <div className="container mx-auto p-6 space-y-8">

            {/* Keywords Section */}
            <div className="p-8 bg-gray-100 rounded-lg shadow-md">

                <div className="flex items-center justify-between mb-6 border-b border-gray-300 pb-4">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <FaGift className="mr-2 text-blue-500"/> Keywords
                    </h2>
                    <p className="text-gray-500 text-sm italic">Top keywords you are tracking</p>
                </div>
                <ul className="flex flex-wrap gap-2">
                    {keywords.map((keyword, index) => (
                        <li key={index} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                            {keyword}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="p-8 bg-gray-100 rounded-lg shadow-md">
                <div className="flex items-center justify-between mb-6 border-b border-gray-300 pb-4">
                    <h2 className="text-xl font-bold text-gray-900 flex items-center">
                        <FaUsers className="mr-2 text-blue-500"/> Competitors
                    </h2>
                    <p className="text-gray-500 text-sm italic">Top ranking products for the keywords</p>
                </div>

                {/* Competitors Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {competitors.map((competitor, index) => (
                        <CompetitorCard
                            key={index}
                            index={index}
                            title={competitor.title}
                            image={competitor.image}
                            url={competitor.url}
                            bulletPoints={competitor.bullet_points}
                        />
                    ))}
                </div>
            </div>
            <div className="p-8 bg-gray-100 rounded-lg shadow-md">
                <div className="flex items-center justify-between mb-6 border-b border-gray-300 pb-4">
                    <h2 className="text-xl font-bold text-gray-700 mb-4 flex items-center">
                        <FaRegCheckCircle className="mr-2 text-green-500"/> Recommendation Summary
                    </h2>
                    <p className="text-gray-500 text-sm italic">Our recommendation based on your and top competitor's
                        data</p>
                </div>
                <div className="mb-4">
                    <p className="text-lg font-semibold text-gray-800">
                        Recommended Title:
                    </p>
                    <p className="text-gray-600 pl-4">{recommendations.title}</p>
                </div>
                <div className="mb-4">
                    <p className="text-lg font-semibold text-gray-800">
                        Recommended Bullet Points:
                    </p>
                    <ul className="list-disc pl-8 space-y-2">
                        {recommendations.bullet_points.map((point, index) => (
                            <li key={index} className="text-gray-600">{point}</li>
                        ))}
                    </ul>
                </div>
                <Link
                    to={`/abtests/create?testType=Title&title=${encodeURIComponent(recommendations.title)}&productId=${productId}`}>
                    <button
                        className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition-colors">
                        Test the Recommendation
                    </button>
                </Link>

            </div>
        </div>
    );
};
export default Recommendations;
