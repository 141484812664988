import React from "react";

function TableRow({ label, value }) {
    return (
        <tr className="w-full">
            <td className="py-2 px-4 text-gray-700 font-medium w-1/2">{label}</td>
            <td className="py-2 px-4 text-gray-600 w-1/2">{value}</td>
        </tr>
    );
}

export default TableRow;
