
import FAQItem from "../../components/faqs/FAQItem";
const faqs = [
  {
    question: "What is this app about?",
    answer: "This app provides A/B testing and recommends product attributes to increase sales.",
  },
  {
    question: "How do I use the A/B testing feature?",
    answer: "Navigate to the A/B testing page and select the attributes you want to test. You can view results in real-time.",
  },
  {
    question: "Why can't I get the test results as soon as it it complete? How long do i have to wait for the results",
    answer: "You have to wait 48 hrs to receive the test results. It takes 48 hrs to get the upto date traffic data from sp-api."
  },
  {
    question: "How does the recommendation engine work?",
    answer: "Our recommendation engine analyzes the traffic data and suggests optimal attributes to enhance product appeal.",
  },
  {
    question: "Is there a limit to the number of products I can test?",
    answer: "No, you can test as many products as you need to optimize your sales strategy.",
  },
];


const FAQPage = () => {
  return (
    <div className="max-w-3xl mx-auto mt-20 p-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Frequently Asked Questions</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQPage;

