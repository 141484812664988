import {Link} from "react-router-dom";
import React from "react";

function EmptyState() {
    return (
        <div className="text-center mt-16">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 mx-auto mb-4 text-gray-400" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M9 13h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h10a2 2 0 012 2v9a2 2 0 01-2 2z"/>
            </svg>
            <h3 className="text-xl font-semibold text-gray-800">No AB Tests Yet</h3>
            <p className="text-gray-500 mt-2">Get started by creating your first AB test.</p>
            <Link to="/create-abtest"
                  className="mt-6 inline-block bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
                <i className="fas fa-plus-circle mr-2"></i> Create AB Test
            </Link>
        </div>
    );
}
export default EmptyState;
