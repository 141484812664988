import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductBox from "../../components/products/ProductBox";
import ProductSkeleton from "../../components/products/ProductSkeleton";
import {FaFrownOpen , FaSearch } from "react-icons/fa";
import {axiosPrivate} from "../../lib/api";

function Products() {
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false); // Loading state

    const fetchProducts = async () => {
        setLoading(true); // Set loading to true when fetching starts
        try {
            const response = await axiosPrivate.get('products/api/products/', {
                params: {
                    page: page,
                    search_text: searchText,
                },
            });
            setProducts(response.data.results);
            setHasNextPage(response.data.next !== null);
            setHasPrevPage(response.data.previous !== null);
            setTotalPages(Math.ceil(response.data.count / 9));  // Assuming 9 items per page
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false); // Set loading to false when fetching ends
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [page, searchText]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearchSubmit = () => {
        setPage(1);  // Reset to the first page when searching
        fetchProducts();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submit behavior
            handleSearchSubmit();
        }
    };

    return (
        <div>
            <h1 className="text-3xl font-bold mb-6">My Products</h1>
            <div className="flex mb-6">
                <div className="relative w-full">
                    <input
                        type="text"
                        value={searchText}
                        onChange={handleSearch}
                        onKeyPress={handleKeyPress} // Trigger search on Enter
                        placeholder="Search by Item Name, SKU, or ASIN"
                        className="border border-gray-300 p-3 rounded-md w-full pl-10 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200"
                    />
                    {/* Search Icon inside input */}
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FaSearch className="h-5 w-5 text-gray-400"/>
                    </div>
                </div>
            </div>

            {!loading && products.length === 0 && (
                <div className="flex flex-col items-center py-8">
                    <div className="flex flex-col items-center justify-center p-4 bg-gray-100 ">
                        <p className="text-gray-500 text-3xl"><FaFrownOpen/></p>
                        <p className="text-gray-600 text-3xl font-medium my-6">No Products Available</p>
                        <p className="text-gray-400 text-lg">You can add new products from the products page</p>
                    </div>
                </div>


            )}

            {loading ? <ProductSkeleton/> : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {products.map(product => <ProductBox key={product.id} product={product}/>)}
                </div>
            )}

            {/* Pagination controls */}
            {!loading && products.length > 0 && (
                <div className="mt-6">
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        {hasPrevPage && (
                            <>
                                <button onClick={() => setPage(1)}
                                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    First
                                </button>
                                <button onClick={() => setPage(page - 1)}
                                        className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    Previous
                                </button>
                            </>
                        )}
                        <span
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-50 text-sm font-medium text-indigo-600">
                            Page {page} of {totalPages}
                        </span>
                        {hasNextPage && (
                            <>
                                <button onClick={() => setPage(page + 1)}
                                        className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    Next
                                </button>
                                <button onClick={() => setPage(totalPages)}
                                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    Last
                                </button>
                            </>
                        )}
                    </nav>
                </div>
            )}
        </div>
    );
}

export default Products;
