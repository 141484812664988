import React from 'react';

const ProductBox = ({product}) => {
    return (
        <div key={product.id}
             className="bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105 duration-300">
            <a href={`/products/${product.product_id}`} className="block">
                {product.image ? (
                    <img src={product.image.link} alt={product.attributes.item_name}
                         className="w-full h-72 object-cover"/>
                ) : (
                    <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500">No Image Available</span>
                    </div>
                )}
                {/* Product Details */}
                <div className="p-6">
                    <h2 className="text-lg font-bold text-gray-800 mb-2 truncate max-w-full">{product.attributes.item_name}</h2>
                    <p className="text-green-500 font-semibold mb-2">${product.attributes.list_price}</p>
                    <span
                        className={`px-2 py-1 rounded-full text-xs font-medium ${product.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                            {product.status}
                                        </span>
                </div>
            </a>
        </div>
    )
}
export default ProductBox;