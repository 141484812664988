import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:8000/'
        : 'https://abtest-dw9b.onrender.com'

const axiosApi = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    timeout: 300000,
});

export default axiosApi;

export const axiosPrivate = axios.create({
    baseURL,
    headers: {"Content-Type": "application/json"},
    withCredentials: true,
    timeout: 300000,
});

const refreshToken = async () => {
    try {
        const response = await axiosApi.post('/user/refresh/');
        if (response.status === 200) {
            console.log('Token refreshed successfully');
            return response.data;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw new Error('Token refresh failed');
    }
};

export const logout = async () => {
    try {
        await axiosPrivate.post('/user/logout/');
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        console.log('Logout successful');
    } catch (error) {
        console.error('Error during logout:', error);
        throw new Error('Logout failed');
    }
};


axiosPrivate.interceptors.response.use(
    (response) => response, // If the response is successful, return it as is
    async (error) => {
        const originalRequest = error.config;
        // Check if the error status is 401 (Unauthorized) and the request hasn't been retried yet
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await refreshToken();
                return axiosPrivate(originalRequest); // Retry the original request with the new token
            } catch (refreshError) {
                await logout();
                // window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);


