import React from "react";

const ProductSkeleton = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[...Array(6)].map((_, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="block">
                        {/* Image Skeleton */}
                        <div className="w-full h-72 bg-gray-200 animate-pulse"></div>

                        {/* Product Details Skeleton */}
                        <div className="p-6">
                            <div className="h-4 bg-gray-200 rounded-md animate-pulse mb-2 w-3/4"></div>
                            <div className="h-4 bg-gray-200 rounded-md animate-pulse mb-2 w-1/3"></div>
                            <div className="h-4 bg-gray-200 rounded-full animate-pulse w-1/4"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ProductSkeleton;