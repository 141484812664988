import React from 'react';

const ImagesTab = ({imagesByVariant}) => {
    return (
        <div id="#images" className="tab-content">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {Object.keys(imagesByVariant).map(variant => {
                    const link = imagesByVariant[variant];
                    return (
                        <div key={variant} className="bg-white rounded-lg shadow-md p-4">
                            <h2 className="text-lg font-semibold mb-2">{variant}</h2>
                            <img src={link} alt={`${variant}`} className="w-full h-auto"/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ImagesTab;
