import React, {useEffect, useRef, useState} from "react";
import Chart from 'chart.js/auto';
const ChartComp = ({labels, chartValues}) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const containerRef = useRef(null);
    const [startIndex, setStartIndex] = useState(0);
    const itemsToShow = 20;

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const endIndex = Math.min(startIndex + itemsToShow, labels.length);
        let chartContent = []
        chartValues.map(chartValue => {
               chartContent.push({
                   label: chartValue.label,
                   data: chartValue.data.slice(startIndex, endIndex),
                   borderColor: chartValue.borderColor,
                   tension: chartValue.tension
               })
        })
        const visibleLabels = labels.slice(startIndex, endIndex);

        const ctx = chartRef.current.getContext('2d');

        chartInstance.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: visibleLabels,
                datasets: chartContent
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Date'
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Value'
                        },
                        beginAtZero: true
                    }
                }
            }
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [labels, chartValues, startIndex]);

    const handlePrev = (e) => {
        e.preventDefault();
        setStartIndex(Math.max(0, startIndex - itemsToShow));
    };

    const handleNext = (e) => {
        e.preventDefault();
        setStartIndex(Math.min(labels.length - itemsToShow, startIndex + itemsToShow));
    };

    const showNavButtons = labels.length > itemsToShow;

    return (
        <div id="chart" className="tab-content">
            <div className="container mx-auto py-8">
                <div ref={containerRef} className="bg-white p-6 rounded-lg shadow-md relative"
                     style={{height: '600px'}}>
                    <canvas ref={chartRef} style={{width: '100%', height: '100%'}}></canvas>
                </div>
                {showNavButtons && (
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={handlePrev}
                            disabled={startIndex === 0}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                        >
                            ← Previous
                        </button>
                        <button
                            onClick={handleNext}
                            disabled={startIndex + itemsToShow >= labels.length}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                        >
                            Next →
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
export default ChartComp;
