import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

const Modal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm transition-opacity duration-300 ease-out">
      <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md transform transition-all duration-300 ease-out scale-100 sm:scale-105">

        {/* Modal Header */}
        <div className="flex items-center space-x-3 mb-4">
          <FiAlertCircle className="text-red-500 text-3xl" />
          <h2 className="text-xl font-bold text-gray-800">Confirm Action</h2>
        </div>

        {/* Modal Body */}
        <p className="text-gray-600 text-sm mb-4 leading-relaxed">
          {message}
        </p>
        <p className="text-red-500 text-xs mb-4">Note: This action cannot be undone.</p>

        {/* Modal Footer */}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={onClose}
            className="px-5 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-100 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-5 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 transition-colors duration-200 shadow-md"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
