import React from 'react';
import ImagesComparision from "./ImagesComparision";

const ComparisonSection = ({abtest}) => {
    console.log(abtest)
    return (
        <div className="bg-white rounded-lg shadow-md mb-10">
            {/* Header Section */}
            <div
                className="p-6 bg-blue-100 text-gray-800 text-2xl font-semibold rounded-t-lg flex items-center justify-center">
                <span>Testing Focus: {abtest.type}</span>
            </div>

            {/* Content Section */}
            <div className="p-6">
                <div className="divide-y divide-gray-200">
                    {abtest.type === "Image" ? <ImagesComparision imageVariants={abtest.image_variant}/> : (
                        <>
                            {/* Comparison Header */}
                            <div className="bg-gray-100 px-6 py-4 grid grid-cols-2 text-center">
                                <div className="text-sm font-semibold text-gray-500 uppercase tracking-wider">
                                    Original
                                </div>
                                <div className="text-sm font-semibold text-gray-500 uppercase tracking-wider">
                                    New
                                </div>
                            </div>

                            {/* Comparison Rows */}
                            <div className="bg-white divide-y divide-gray-200">
                                {/* Title Variant */}
                                {abtest.title_variant ? (
                                    <div className="px-6 py-4 grid grid-cols-2 gap-5">
                                        <div className="text-lg font-medium text-gray-700">
                                            {abtest.title_variant.original_title}
                                        </div>
                                        <div className="text-lg font-medium text-gray-700">
                                            {abtest.title_variant.new_title}
                                        </div>
                                    </div>
                                ) : null}

                                {/* Description Variant */}
                                {abtest.description_variant ? (
                                    <div className="px-6 py-4 grid grid-cols-2 gap-5">
                                        <div className="text-lg font-medium text-gray-700">
                                            {abtest.description_variant.original_description}
                                        </div>
                                        <div className="text-lg font-medium text-gray-700">
                                            {abtest.description_variant.new_description}
                                        </div>
                                    </div>
                                ) : null}

                                {/* Bullet Points Variant */}
                                {abtest.bullet_points_variant ? (
                                    <div className="px-6 py-4 grid grid-cols-2 gap-5">
                                        <ul className="text-lg font-medium text-gray-700 list-disc pl-5 space-y-2">
                                            {abtest.bullet_points_variant.original_bullet_points.map((bullet, index) => (
                                                <li key={index}>{bullet.value}</li>
                                            ))}
                                        </ul>
                                        <ul className="text-lg font-medium text-gray-700 list-disc pl-5 space-y-2">
                                            {abtest.bullet_points_variant.new_bullet_points.map((bullet, index) => (
                                                <li key={index}>{bullet.value}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}

                                {/* Price Variant */}
                                {abtest.price_variant ? (
                                    <div className="px-6 py-4 grid grid-cols-2 text-center">
                                        <div className="text-lg font-medium text-gray-700">
                                            ${abtest.price_variant.original_price}
                                        </div>
                                        <div className="text-lg font-medium text-gray-700">
                                            ${abtest.price_variant.new_price}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    )}


                </div>
            </div>
        </div>
    );
};

export default ComparisonSection;
