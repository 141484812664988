import React from 'react';

const FeatureCard = ({ icon: Icon, title, description, iconColor }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-between h-full hover:scale-105 transform transition-transform duration-300">
      <Icon className={`text-5xl mb-4 ${iconColor}`} />
      <h2 className="text-xl font-semibold text-gray-800 text-center">{title}</h2>
      <p className="text-gray-600 mt-2 text-center">{description}</p>
    </div>
  );
};



export default FeatureCard;
