import React, {useState, useEffect, useRef} from 'react';
import {useSearchParams} from "react-router-dom";
import {axiosPrivate} from '../../lib/api';
import TestTitle from "../../components/createabtest/TestTitle";
import TestBulletPoints from "../../components/createabtest/TestBulletPoints";
import TestPrice from "../../components/createabtest/TestPrice";
import TestImage from "../../components/createabtest/TestImage";
import {useNavigate} from "react-router-dom";
import {FaInfoCircle} from "react-icons/fa";
import {notify} from "../../components/UI/Toast";

function formatNextDayMidnight() {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setUTCDate(today.getUTCDate() + 1); // Add 1 day in UTC
    nextDay.setUTCHours(0, 0, 0, 0); // Set time to 12:00 AM UTC

    // Format the date to a readable format (e.g., MM/DD/YYYY 12:00 AM UTC)
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC',
        timeZoneName: 'short'
    };
    return nextDay.toLocaleString('en-US', options);
}

const CreateAbTestForm = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [hypothesis, setHypothesis] = useState('');
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);//1433
    const [testDuration, setTestDuration] = useState(7);
    const [error, setError] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [testType, setTestType] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [bulletPoints, setBulletPoints] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [asinConfirmation, setAsinConfirmation] = useState('');
    const [errors, setErrors] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false)
    const nextDayMidnight = formatNextDayMidnight();
    const [searchParams] = useSearchParams();
    let originalImagesNumber = useRef(0);
    useEffect(() => {
        fetchProducts();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setErrors({});
    }, [testType, asinConfirmation]);

    useEffect(() => {
        const testTypeFromRecom = searchParams.get('testType');
        const newTitleFromRecom = searchParams.get('title');
        const productIdFromRecom = searchParams.get('productId');
        if (productIdFromRecom) {
            setTestType(testTypeFromRecom);
            setNewTitle(newTitleFromRecom);
            setSelectedProductId(productIdFromRecom);
        }
    }, [searchParams])

    useEffect(() => {
        if (selectedProductId) {
            handleProductSelection(selectedProductId)
        }
    }, [selectedProductId])

    const handleTestTypeSelection = (type) => {
        setTestType(type);
        setErrors({});
    };

    const fetchProducts = async () => {
        try {
            const response = await axiosPrivate.get('abtests/api/create_abtest');
            setProducts(response.data);
        } catch (error) {
            setErrors('Error fetching products:');
        }
    };

    const handleProductSelection = async (productId) => {
        try {
            const response = await axiosPrivate.post('abtests/api/create_abtest/', {
                'step': 'SELECT_PRODUCT',
                'product_id': productId
            });
            const selectedProductData = response.data
            setSelectedProduct(selectedProductData);
            setBulletPoints((selectedProductData.bullet_points || []))
            setImageList((selectedProductData.images || []))
            setDropdownOpen(false);
            originalImagesNumber.current = selectedProductData.original_images_number
        } catch (error) {
            setErrors('Error Selecting Product:');
        }
    }
    const validateForm = () => {
        let validationErrors = {};
        if (!name) {
            validationErrors.name = 'Please enter the name for the test.';
        }
        if (!hypothesis) {
            validationErrors.hypothesis = 'Please enter the hypothesis for the test.';
        }
        if (!selectedProduct) {
            validationErrors.selectedProduct = 'Please select a product.';
        }
        if (!testDuration) {
            validationErrors.testDuration = 'Please select a test duration.';
        }
        if (!testType) {
            validationErrors.testType = 'Please select a test type.';
        }
        if (testType === 'Title' && !newTitle.trim()) {
            validationErrors.newTitle = 'Please enter a new title.';
        }
        if (testType === 'Price' && !newPrice.trim()) {
            validationErrors.newPrice = 'Please enter a new price.';
        }
        if (!bulletPoints.length) {
            validationErrors.bulletPoints = "Please enter at least a bullet point";
        }
        const {asin1} = selectedProduct;
        if (!asinConfirmation || asinConfirmation !== asin1) {
            validationErrors.asinConfirmation = 'Please enter the correct ASIN.';
        }
        return validationErrors;
    };

    const submitDataToApi = async () => {
        setSubmitLoading(true)
        const formData = new FormData();
        formData.append('name', name);
        formData.append('hypothesis', hypothesis);
        formData.append('product_id', selectedProduct.id);
        formData.append('bulletPoints', JSON.stringify(bulletPoints));
        formData.append('testDuration', testDuration);
        formData.append('testType', testType);
        formData.append('newTitle', newTitle);
        formData.append('newPrice', newPrice);
        imageList.forEach((image, index) => {
            formData.append(`image_${index}`, image);
        });
        try {
            const response = await axiosPrivate.post('abtests/api/create_abtest/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const abtestId = response.data.abtest_id;
            navigate(`/abtests/${abtestId}`);
            notify.success("Ab Test Created")
        } catch (error) {
            console.log(error)
            setSubmitLoading(false)
            setErrors({'serverError': 'Some server error has occurred. Please try again later.'})
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setError(true);
            return;
        }
        setErrors({});
        setError(false);
        return submitDataToApi()
    };
    return (
        <div className="bg-white rounded-lg shadow-xl p-8 sm:max-w-5xl sm:w-full mx-auto my-10">
            <div className="mb-8">
                <h3 className="text-3xl font-bold text-blue-700 flex items-center space-x-2">
                    <span>Create AB Test</span>
                </h3>
                <p className="text-gray-500 mt-2">Test different aspects of your product to optimize performance.</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label className="block text-lg font-medium text-gray-800" htmlFor="title">Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Enter test title"
                    />
                    {errors.name && <div className="text-red-600 my-1">{errors.name}</div>}
                </div>
                <div className="mb-6">
                    <label className="block text-lg font-medium text-gray-800" htmlFor="hypothesis">Hypothesis</label>
                    <textarea
                        value={hypothesis}
                        onChange={(e) => setHypothesis(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Enter hypothesis"
                    ></textarea>
                    {errors.hypothesis && <div className="text-red-600 my-1">{errors.hypothesis}</div>}
                </div>
                <div className="mb-6">
                    <div className="flex items-center mb-4">
                        <label className="block text-lg font-medium text-gray-800 mr-2">Select a Product</label>
                        <span className="text-sm text-gray-600 italic flex items-center"><FaInfoCircle
                            className="mr-1 text-blue-500"/> <span>Product selection is not available if an active test already exists.</span></span>
                    </div>
                    <div className="relative mt-2">
                        <button
                            type="button"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="w-full bg-white border border-gray-300 rounded-lg p-3 text-left text-gray-700 hover:bg-gray-100 flex items-center justify-between"
                        >
                            {selectedProduct ? selectedProduct.item_name : 'Select a Product'}
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                            </svg>
                        </button>

                        {/* Dropdown Content */}
                        {dropdownOpen && (
                            <div
                                className="absolute z-10 w-full bg-white border border-gray-300 mt-2 rounded-lg shadow-lg">
                                {/* Search Bar */}
                                <input
                                    type="text"
                                    placeholder="Search products..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-full p-3 border-b border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                />
                                {/* Product List */}
                                <ul className="max-h-48 overflow-y-auto divide-y divide-gray-200">
                                    {products
                                        .filter((product) =>
                                            product.item_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                            product.seller_sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                            product.asin1.toLowerCase().includes(searchTerm.toLowerCase())
                                        )
                                        .map((product) => (
                                            <li
                                                key={product.id}
                                                className="p-3 hover:bg-blue-100 cursor-pointer"
                                                onClick={() => setSelectedProductId(product.id)}
                                            >
                                                <p>{product.item_name}</p>
                                                <p className="text-sm text-gray-500">
                                                    SKU: {product.seller_sku} | ASIN: {product.asin1}
                                                </p>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {errors.selectedProduct && <div className="text-red-600 my-1">{errors.selectedProduct}</div>}
                </div>
                <div className="mb-6">
                    <label
                        className="block text-lg font-medium text-gray-800 flex items-center gap-2"
                        htmlFor="end_date">
                        Test Duration
                        <span className="text-sm text-gray-600 italic flex items-center">
                            <FaInfoCircle className="mr-1 text-blue-500"/>
                            <span>Test will start from {nextDayMidnight}</span>
                        </span>
                    </label>
                    <div className="flex space-x-4 mt-4">
                        <button
                            type="button"
                            className={`px-6 py-3 rounded-lg focus:outline-none transition-all duration-300 ease-in-out transform ${
                                testDuration === 7
                                    ? 'bg-blue-600 text-white shadow-lg scale-105'
                                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300 hover:scale-105'
                            }`}
                            onClick={() => setTestDuration(7)}
                        >
                            Run for 7 days
                        </button>
                        <button
                            type="button"
                            className={`px-6 py-3 rounded-lg focus:outline-none transition-all duration-300 ease-in-out transform ${
                                testDuration === 14
                                    ? 'bg-blue-600 text-white shadow-lg scale-105'
                                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300 hover:scale-105'
                            }`}
                            onClick={() => setTestDuration(14)}
                        >
                            Run for 14 days
                        </button>
                    </div>
                </div>
                {selectedProduct ? (
                    <div>
                        <div className="mb-6">
                            <label className="block text-lg font-medium text-gray-800">What are you testing?</label>
                            <div className="grid grid-cols-2 gap-4 mt-4">
                                <div
                                    className={`cursor-pointer p-4 border border-gray-300 rounded-lg text-center hover:bg-blue-100 flex flex-col items-center space-y-2 ${testType === 'Title' ? 'bg-blue-300' : 'bg-white'}`}
                                    onClick={() => handleTestTypeSelection('Title')}
                                >
                                    <p className="text-lg font-medium">Title</p>
                                </div>

                                <div
                                    className={`cursor-pointer p-4 border border-gray-300 rounded-lg text-center  hover:bg-blue-100 flex flex-col items-center space-y-2 ${testType === 'Bullet Points' ? 'bg-blue-300' : 'bg-white'}`}
                                    onClick={() => handleTestTypeSelection('Bullet Points')}
                                >
                                    <p className="text-lg font-medium">Bullet Points</p>
                                </div>

                                <div
                                    className={`cursor-pointer p-4 border border-gray-300 rounded-lg text-center hover:bg-blue-100 flex flex-col items-center space-y-2 ${testType === 'Price' ? 'bg-blue-300' : 'bg-white'}`}
                                    onClick={() => handleTestTypeSelection('Price')}
                                >
                                    <p className="text-lg font-medium">Price</p>
                                </div>
                                <div
                                    className={`cursor-pointer p-4 border border-gray-300 rounded-lg text-center hover:bg-blue-100 flex flex-col items-center space-y-2 ${testType === 'Image' ? 'bg-blue-300' : 'bg-white'}`}
                                    onClick={() => handleTestTypeSelection('Image')}
                                >
                                    <p className="text-lg font-medium">Image</p>
                                </div>
                            </div>
                            {errors.testType && <div className="text-red-600 my-1">{errors.testType}</div>}
                        </div>
                        {testType === 'Title' && (
                            <TestTitle
                                error={errors.newTitle}
                                newTitle={newTitle}
                                setNewTitle={setNewTitle}
                                selectedProduct={selectedProduct}
                            />
                        )}
                        {testType === 'Bullet Points' &&
                            <TestBulletPoints
                                error={errors.bulletPoints}
                                bulletPoints={bulletPoints}
                                setBulletPoints={setBulletPoints}/>}
                        {testType === 'Price' && (
                            <TestPrice
                                errors={errors}
                                newPrice={newPrice}
                                setNewPrice={setNewPrice}
                                selectedProduct={selectedProduct}
                            />
                        )}
                        {testType === 'Image' && (
                            <TestImage
                                imageList={imageList}
                                setImageList={setImageList}
                                originalImagesNumber={originalImagesNumber}
                            />
                        )}
                        <div className="mt-6 p-6 border-2 border-red-600 border-dotted rounded-lg bg-red-50">
                            <div className="flex items-center">
                                <p className="text-red-700 font-bold text-lg mr-2">⚠️ Warning:</p>
                                <p className="text-red-600">
                                    Creating this A/B test will modify your Amazon product. Are you sure you want to
                                    proceed with this change?
                                </p>
                            </div>

                            {/* ASIN Confirmation */}
                            <div className="mt-6">
                                <label className="block text-lg font-medium text-red-700 mb-2">Please confirm the ASIN
                                    of the product:</label>
                                <input
                                    type="text"
                                    name="asin_confirmation"
                                    placeholder="Enter ASIN of the product"
                                    value={asinConfirmation}
                                    onChange={(e) => setAsinConfirmation(e.target.value)}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                />
                                {errors.asinConfirmation && (
                                    <div className="text-red-600 mt-2">{errors.asinConfirmation}</div>
                                )}
                            </div>

                            {/* Image Warning Notice */}
                            {testType === "Image" && (
                                <div
                                    className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-6 rounded-lg">
                                    <h3 className="font-semibold text-lg">Important Notice</h3>
                                    <p className="mt-2">
                                        Image updates may take up to a minute. Do not refresh the page during this time,
                                        as it may disrupt the update process on Amazon and lead to unexpected issues.
                                    </p>
                                </div>
                            )}

                            {/* Submit Button */}
                            <div className="mt-8">
                                <button
                                    type="submit"
                                    className={`w-full inline-flex justify-center items-center rounded-lg border border-transparent shadow-sm px-6 py-3 ${submitLoading ? "bg-green-300 text-gray-800" : "bg-green-500 text-white"} text-lg font-medium hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-300`}
                                >
                                    {submitLoading ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </div>

                    </div>
                ) : (
                    <div className="flex justify-center mt-8">
                        <button
                            type="button"
                            className="px-6 py-3 bg-blue-400 text-white font-semibold rounded-lg shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300"
                            onClick={() => setDropdownOpen(true)}
                        >
                            Select a Product to Proceed
                        </button>
                    </div>
                )}
                {error && (
                    <div className="mt-4 text-red-600 font-semibold text-center">
                        Please fix the error mentioned above
                    </div>
                )}
                {errors.serverError && (
                    <div className="mt-4 text-red-600 font-semibold text-center">
                        {errors.serverError}
                    </div>
                )}
            </form>
        </div>
    );
};

export default CreateAbTestForm;
