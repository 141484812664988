import React from 'react';
import ImageCard from './ImageCard';
import UploadImage from './UploadImage';

const ImageGrid = ({ imageList, moveImage, handleFileInputChange, handleDeleteImage, handleDrop, preventDefaults, oIN }) => {
  const imageListSize = imageList.length;
  console.log(imageListSize)
  return (
    <div id="image_wrapper" className="flex flex-wrap gap-6 justify-start">
      {imageList.map((image, index) => (
          <ImageCard
            key={index}
            image={image}
            index={index}
            oIN={oIN}
            moveImage={moveImage}
            handleFileInputChange={handleFileInputChange}
            handleDeleteImage={handleDeleteImage}
          />
      ))}
      {imageListSize < 10 && (
          <UploadImage
            index={imageListSize}
            handleDrop={handleDrop}
            preventDefaults={preventDefaults}
            handleFileInputChange={handleFileInputChange}
          />
      )}

    </div>
  );
};

export default ImageGrid;
