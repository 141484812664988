import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="text-center">
        {/* FontAwesome Icon */}
        <div className="text-blue-500 text-9xl mb-4">
          <i className="fas fa-exclamation-triangle"></i>
        </div>
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-xl text-gray-600 mb-8">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="inline-block bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          <i className="fas fa-home mr-2"></i>
          Go Back Home
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
