import React from 'react';
import { FaSearch } from 'react-icons/fa';

function SearchBar({ searchQuery, setSearchQuery }) {
    return (
        <div className="relative w-1/3">
            <input
                type="text"
                placeholder="Search by ASIN, Item Name, or Seller SKU"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FaSearch className="text-gray-400 h-5 w-5" />
            </div>
        </div>
    );
}

export default SearchBar;
