import React from 'react';
import SearchBar from '../../components/abtests/SearchBar';
import FilterButtons from '../../components/abtests/FilterButtons';
import SortDropdown from '../../components/abtests/SortDropdown';

function Controls({ searchQuery, setSearchQuery, showExpired, setShowExpired, totalTestsCount, activeTestsCount, expiredTestsCount, sortBy, setSortBy }) {
    return (
        <div className="flex justify-between items-center mb-4">
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <FilterButtons
                showExpired={showExpired}
                setShowExpired={setShowExpired}
                totalTestsCount={totalTestsCount}
                activeTestsCount={activeTestsCount}
                expiredTestsCount={expiredTestsCount}
            />
            <SortDropdown sortBy={sortBy} setSortBy={setSortBy} />
        </div>
    );
}

export default Controls;
