import React, { createContext, useState, useEffect } from 'react';
import { axiosPrivate } from "../lib/api"; // Axios instance

// Create the User Context
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserAuthentication = async () => {
      try {
        const response = await axiosPrivate.get('/user/api/get-user');
        if (response.status === 200) {
          setUser(response.data);
        } else {
          setUser(null);
        }
        setLoading(false)
      } catch (error) {
        setUser(null);
        setLoading(false)
      }
    };

    checkUserAuthentication();
  }, []);
  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
