

import React, { useState } from 'react';
import { FiRefreshCw, FiPauseCircle, FiArchive } from 'react-icons/fi';
import { axiosPrivate } from "../../lib/api";
import { notify } from '../../components/UI/Toast';
import Modal from './ActionModal';

const AbTestActions = ({ abTestData, setAbTestData, abTestId,  }) => {
  const actions = [
  {
    label: 'Extend by 7 Days',
    icon: FiRefreshCw,
    tooltip: 'Extend the test by 7 days',
    buttonStyle: 'bg-blue-500',
    message: 'Are you sure you want to extend the test by 7 days?',
    successMessage: 'Ab Test updated by 7 days',
    errorMessage: 'Error extending the test',
    apiData: { days: 7, action: "extend" }
  },
  {
    label: 'Extend by 14 Days',
    icon: FiRefreshCw,
    tooltip: 'Extend the test by 14 days',
    buttonStyle: 'bg-blue-500',
    message: 'Are you sure you want to extend the test by 14 days?',
    successMessage: 'Ab Test updated by 14 days',
    errorMessage: 'Error extending the test',
    apiData: { days: 14, action: "extend" }
  },
  // Additional actions can be added here
];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [currentAction, setCurrentAction] = useState(null);

  const openModal = (action) => {
    setCurrentAction(action);
    setModalMessage(action.message);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleConfirmAction = async () => {
    if (!currentAction) return;

    try {
      const response = await axiosPrivate.post(`abtests/api/get_abtest/${abTestId}`, {
        ...currentAction.apiData,
      });
      if (response.status === 200) {
        setAbTestData(response.data);
        notify.success(currentAction.successMessage);
      }
    } catch (e) {
      notify.error(currentAction.errorMessage || "An error occurred");
    }
    closeModal();
  };

  return (
      <>
    <div className="flex flex-col items-center w-full mt-6 px-6 py-12 bg-white shadow rounded-lg">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">A/B Test Actions</h2>
        <p className="text-gray-600 mt-2">Manage the current A/B test by selecting an action below.</p>
      </div>

      <div className="flex flex-wrap gap-4 justify-around">
        {actions.map((action, index) => (
          <div key={index} className="relative group mb-5">
            <button
              onClick={() => openModal(action)}
              className={`flex items-center justify-center space-x-2 px-4 py-2 ${action.buttonStyle} text-white rounded-md w-full hover:bg-opacity-80 transition ease-in-out duration-200`}
            >
              {action.icon && React.createElement(action.icon, { className: 'text-xl' })}
              <span>{action.label}</span>
            </button>
            <span className="absolute left-0 w-full mt-2 text-center text-gray-600 text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              {action.tooltip}
            </span>
          </div>
        ))}
      </div>


    </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirmAction}
        message={modalMessage}
      />
  </>
  );
};

export default AbTestActions;
