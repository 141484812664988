import React from 'react';
import { FaUpload, FaTrashAlt } from 'react-icons/fa';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

const ImageCard = ({ image, index, moveImage, handleFileInputChange, handleDeleteImage, oIN }) => {
  return (
    <div className="image-box relative mb-5">
      <div className="flex justify-center items-center border rounded-lg shadow-lg p-4 w-64 h-64 relative">
        <img src={image} alt="Current Image" className="max-w-full max-h-full object-contain" />
        {index ==0 && (<div className="absolute bottom-0 bg-black bg-opacity-70 left-0 right-0 text-center p-1 text-white font-semibold">Main Image</div>)}
      </div>
      <div className="flex justify-center space-x-4 mt-4 items-center">
        {/* Move Left Button */}
        <button
          type="button"
          onClick={() => moveImage(index, -1)}
          className="bg-gray-200 hover:bg-gray-300 text-gray-600 py-2 px-4 rounded-full shadow-md transition-all duration-200 flex items-center justify-center"
          title="Move Left"
        >
          <GrFormPrevious size={22} />
        </button>
        <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full shadow-md transition-all duration-200 flex items-center justify-center space-x-2"
          title="Upload Image"
        >
          <FaUpload size={18} />
          <input
            type="file"
            name="new_image"
            className="hidden"
            onChange={(e) => handleFileInputChange(e, index)}
          />
        </label>
        {index >= oIN && (
            <button
                type="button"
                onClick={() => handleDeleteImage(index)}
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full shadow-md transition-all duration-200 flex items-center justify-center"
                title="Delete Image"
            >
              <FaTrashAlt size={18}/>
            </button>
        )}
        <button
            type="button"
            onClick={() => moveImage(index, 1)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-600 py-2 px-4 rounded-full shadow-md transition-all duration-200 flex items-center justify-center"
            title="Move Right"
        >
        <GrFormNext size={20} />
        </button>
      </div>
    </div>
  );
};

export default ImageCard;
