import React from 'react';

const VariantsTab = ({ variants }) => {
    console.log(variants)
  return (
    <div id="orders" className="tab-content">
      <ul>
        {variants.map(variant => (
          <li key={variant.product_id} className="border-b py-4">
            <a href={`/products/${variant.product_id}`} className="flex items-center group hover:bg-gray-100 rounded-lg p-4 transition duration-200 ease-in-out">
              <div className="flex-shrink-0 mr-4">
                <img src={variant.image} alt={variant.title} className="w-24 h-24 object-cover rounded-lg" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600">{variant.title}</h3>
                <p className="text-sm text-gray-600">SKU: {variant.seller_sku} | ASIN: {variant.asin}</p>
                <p className="text-sm text-gray-600">Price: ${variant.price}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VariantsTab;
