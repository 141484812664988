import React from 'react';
import {FaArrowRightLong, FaArrowTrendDown, FaArrowTrendUp} from "react-icons/fa6";

const ABTestResult = ({resultData, abtest}) => {
    console.log(resultData)
    const renderTrendIcon = (type) => {
        if (type === 'increase') {
            return <FaArrowTrendUp className="text-green-400 text-lg"/>;
        } else if (type === 'decrease') {
            return <FaArrowTrendDown className="text-red-500 text-lg"/>;
        } else {
            return <FaArrowRightLong className="text-gray-500 text-lg"/>;
        }
    };

    const renderComparisonRows = () => {
        return resultData.comparisons.map((comparison, index) => (
            <div key={index} className="flex items-stretch hover:bg-gray-50 transition duration-300">
                <div className="w-52 py-4 px-4">
                    <div className="flex items-center justify-between">
                        <span className="font-semibold text-gray-700">{comparison.title}</span>
                        {comparison.info && (
                            <div className="relative group ml-2">
                                <i className="fas fa-info-circle text-blue-500 cursor-pointer"></i>
                                <div
                                    className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs rounded-lg px-3 py-2 opacity-0 group-hover:opacity-100 transition-opacity z-10 w-48 text-center">
                                    {comparison.info}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1 py-4 px-4 text-center bg-blue-100">
                    <span className="text-gray-900 font-bold">{comparison.test_data}</span>
                </div>
                <div className="flex-1 py-4 px-4 text-center text-gray-600">{comparison.prev_data}</div>
                <div className="w-96 py-4 px-4 text-center text-gray-600">{comparison.message}</div>
                <div className="flex-1 py-4 px-6 text-center">
                    {renderTrendIcon(comparison.type)}
                </div>
            </div>
        ));
    };

    const renderResultMessage = () => {
        if (resultData.result_type === 'positive') {
            return {background: '#d1fae5', borderColor: '#10b981', color: '#065f46'};
        } else if (resultData.result_type === 'negative') {
            return {background: '#fee2e2', borderColor: '#ef4444', color: '#991b1b'};
        } else {
            return {background: '#f3f4f6', borderColor: '#6b7280', color: '#374151'};
        }
    };

    if (resultData.result_stage === "completed") {
        return (
            <div className="p-6 rounded-lg bg-white shadow-lg">
                <h2 className="text-2xl font-bold text-gray-800 text-center">AB Results Complete</h2>
                <p className="text-gray-600 mt-2 w-3/4 m-auto text-center">
                    AB Result for <strong>{abtest.product.item_name}</strong> is complete.
                </p>

                <div className="container mx-auto p-6">
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        {/* Header Row */}
                        <div className="flex bg-gray-800 text-white font-semibold">
                            <div className="w-52 py-4 px-4 text-left">Metric</div>
                            <div className="flex-1 py-4 px-4 text-center">Ab Test</div>
                            <div className="flex-1 py-4 px-4 text-center">Previous</div>
                            <div className="w-96 py-4 px-4 text-center">Comparison</div>
                            <div className="flex-1 py-4 px-4 text-center">Trend</div>
                        </div>

                        {/* Data Rows */}
                        {renderComparisonRows()}
                    </div>

                    <div className="mt-6 p-6 rounded-lg border-l-4 flex items-center justify-center"
                         style={renderResultMessage()} role="alert">
                        <div className="text-center">
                            <p className="font-bold text-xl">Result Summary</p>
                            <p className="text-lg">{resultData.result_message}</p>
                        </div>
                    </div>

                    {/* Information and Disclaimer Section */}
                    <div className="mt-6 p-4 bg-gray-50 border border-gray-300 rounded-lg text-left">
                        <div className="flex items-center justify-center mb-4">
                            <i className="fas fa-info-circle text-blue-500 text-xl mr-2"></i>
                            <p className="font-semibold text-lg text-gray-700">Important Information</p>
                        </div>
                        <ul className="list-disc pl-8 mt-2 text-gray-600 text-sm space-y-2">
                            <li>
                                The previous traffic data were observed over a <strong>7-day test period</strong>.
                                Changes in user behavior over longer periods
                                may not reflect the same trends.
                            </li>
                            <li>
                                Given the nature of A/B testing, there is a possibility of <strong>false positives or
                                false negatives</strong>.
                                Proceed with caution when making decisions based solely on these results.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col items-center justify-center p-6 rounded-lg bg-white shadow-lg">
                <i className="fas fa-clock text-yellow-500 text-3xl m-2"></i>
                <h2 className="text-2xl font-bold text-gray-800 text-center">Results Pending</h2>
                {resultData.result_stage === "not_complete" ? (
                    <p className="text-gray-600 mt-2 text-center">Your A/B test results will be available once the test
                        is complete.</p>
                ) : (
                    <p className="text-green-600 mt-2 font-semibold text-center">
                        Your A/B test is complete. The results will be available on {resultData.available_date}
                    </p>
                )}
                <p className="text-gray-600 mt-2 text-center">
                    It typically takes around <strong>48 hours</strong> to gather accurate traffic data from Amazon once
                    the test is complete.
                </p>
                <div className="mt-6 p-4 rounded-lg flex items-center">
                    <p className="text-gray-600">Thank you for your understanding!</p>
                </div>
                <div className="mt-6 text-gray-500 text-sm text-center">
                    <p>If you have any questions, feel free to reach out to our support team.</p>
                </div>
            </div>
        );
    }
};

export default ABTestResult;
