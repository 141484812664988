import React, {useEffect, useRef, useState} from 'react';
import ChartComp from "../UI/Chart";

const Chart = ({abtestTrafficData, isTabvisible}) => {
    const reversedData = [...abtestTrafficData].reverse();
    const labels = reversedData.map(result => result.date);
    const pageViewsData = reversedData.map(result => result.page_views);
    const ordersData = reversedData.map(result => result.orders);
    const salesData = reversedData.map(result => result.sales);
    const chartValues = [
        {
            label: 'Sales',
            data: salesData,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        },
        {
            label: 'Orders',
            data: ordersData,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
        },
        {
            label: 'Page Views',
            data: pageViewsData,
            borderColor: 'rgb(54, 162, 235)',
            tension: 0.1
        }
    ]
    return (
        <ChartComp labels={labels} chartValues={chartValues}/>
    )
};

export default Chart;
