import React from "react";

const MetricBox = ({ title, value, icon, color }) => (
  <div className="bg-white shadow rounded-lg p-4 transform transition-all hover:scale-105 hover:shadow-md">
    <div className="flex items-center gap-3">
      <div
        className="text-xl"
        style={{ color: color }}
      >
        {React.cloneElement(icon, { style: { color: color, fontSize: '1.5em' } })}
      </div>
      <div className="text-left">
        <p className="text-2xl font-semibold text-gray-900">{value}</p>
        <p className="text-xs text-gray-500 mt-0.5">{title}</p>
      </div>
    </div>
  </div>
);

export default MetricBox;
