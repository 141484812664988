import React, {useState} from 'react';

const ImagesComparisons = ({imageVariants}) => {
    const {original_images: originalImages, new_images: newImages} = imageVariants;
    const [showOriginal, setShowOriginal] = useState(false);

    const toggleOriginalImages = () => {
        setShowOriginal(!showOriginal);
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-semibold mb-4">New Images</h2>
            <div className="flex flex-wrap gap-10">
                {newImages.map((image, index) => (
                    image && (
                        <div key={index} className="relative">
                            <div
                                className="w-40 h-40 border border-gray-300 flex items-center justify-center rounded-lg bg-gray-100 shadow-lg"
                            >
                                <img
                                    src={image}
                                    alt={`New ${index + 1}`}
                                    className="object-cover h-full w-full rounded-lg"
                                />
                            </div>
                            <div
                                className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-sm py-1 text-center rounded-b-lg"
                            >
                                {index === 0 ? "Main" : `PT0${index}`}
                            </div>
                        </div>
                    )
                ))}
            </div>

            <button
                onClick={toggleOriginalImages}
                className="mt-6 py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg transition-colors duration-300"
            >
                {showOriginal ? 'Hide Original Images' : 'Show Original Images'}
            </button>

            {showOriginal && (
                <div>
                    <h2 className="text-2xl font-semibold mt-6 mb-4">Original Images</h2>
                    <div className="flex flex-wrap gap-10">
                        {originalImages.map((image, index) => (
                            image && (
                                <div key={index} className="relative">
                                    <div
                                        className="w-40 h-40 border border-gray-300 flex items-center justify-center rounded-lg bg-gray-100 shadow-lg"
                                    >
                                        <img
                                            src={image}
                                            alt={`Original ${index + 1}`}
                                            className="object-cover h-full w-full rounded-lg"
                                        />
                                    </div>
                                    <div
                                        className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-sm py-1 text-center rounded-b-lg"
                                    >
                                        {index === 0 ? "Main" : `PT0${index}`}
                                    </div>
                                </div>
                            )
                        ))}

                    </div>
                </div>
            )}
        </div>
    );
};

export default ImagesComparisons;
