import React from 'react';
import { FaChartLine, FaAsterisk, FaRegClock, FaShoppingCart } from 'react-icons/fa';
import FeatureCard from '../components/landingPage/FeatureCard';

const LandingPage = () => {
  const features = [
    {
      icon: FaChartLine,
      title: 'Track Performance',
      description: 'Monitor key metrics like sales, orders, conversion rate, and page views to measure the effectiveness of your tests.',
      iconColor: 'text-blue-600',
    },
    {
      icon: FaAsterisk,
      title: 'Test Different Attributes',
      description: 'Experiment with different attributes such as title, bullet points, price, and images to find the best-performing variants.',
      iconColor: 'text-green-600',
    },
    {
      icon: FaRegClock,
      title: 'Flexible Test Duration',
      description: 'Choose from 7 or 14-day test durations to gather meaningful insights into your product\'s performance.',
      iconColor: 'text-yellow-600',
    },
    {
      icon: FaShoppingCart,
      title: 'Increase Sales',
      description: 'Make data-driven decisions to optimize your product listings and maximize your sales on Amazon.',
      iconColor: 'text-red-600',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center p-8">
      {/* Header Section */}
      <header className="text-center mb-16">
        <h1 className="text-6xl font-extrabold text-gray-900 mb-4 leading-tight">
          Amazon A/B Testing Tool
        </h1>
        <p className="text-xl text-gray-700 mt-4 max-w-2xl mx-auto leading-loose">
          Optimize your Amazon product listings by running data-driven A/B tests on attributes like titles, bullet
          points, images, and prices to boost your sales and conversions.
        </p>
      </header>

      {/* Feature Cards Section */}
      <section className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10 w-full max-w-6xl mx-auto">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
            iconColor={feature.iconColor}
            className="transform hover:scale-105 transition duration-300 ease-in-out"
          />
        ))}
      </section>

      {/* Call to Action Section */}
      <div className="mt-20 text-center">
        <a
          href="/login"
          className="bg-gradient-to-r from-blue-500 to-purple-500 text-white text-lg px-12 py-4 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300"
        >
          Get Started
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
