import React from 'react';

const ProductSkeleton = () => {
    return (
        <div className="container mx-auto p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg mb-6">
                <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
                    <div className="h-8 w-48 bg-gray-300 rounded-md"></div>
                    <div className="h-20 w-20 bg-gray-300 rounded-full"></div>
                </div>
                <div className="h-4 bg-gray-300 rounded-md mt-4 w-full"></div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                    <div className="p-6 border border-gray-200 rounded-lg bg-gray-50 shadow-sm">
                        <span className="text-sm font-medium text-gray-500">Price</span>
                        <div className="h-8 bg-gray-300 rounded-md mt-1"></div>
                    </div>
                    <div className="p-6 border border-gray-200 rounded-lg bg-gray-50 shadow-sm">
                        <span className="text-sm font-medium text-gray-500">ASIN</span>
                        <div className="h-8 bg-gray-300 rounded-md mt-1"></div>
                    </div>
                    <div className="p-6 border border-gray-200 rounded-lg bg-gray-50 shadow-sm">
                        <span className="text-sm font-medium text-gray-500">SKU</span>
                        <div className="h-8 bg-gray-300 rounded-md mt-1"></div>
                    </div>
                </div>
                <div className="h-6 bg-gray-300 rounded-md mt-8 w-48"></div>
                <ul className="list-disc pl-6 mt-4 text-gray-700">
                    <li className="h-4 bg-gray-300 rounded-md mb-2 w-full"></li>
                    <li className="h-4 bg-gray-300 rounded-md mb-2 w-full"></li>
                    <li className="h-4 bg-gray-300 rounded-md mb-2 w-full"></li>
                </ul>
            </div>
            <div className="h-64 bg-gray-300 rounded-md mt-6"></div>
            <div className="h-16 bg-gray-300 rounded-md mt-6"></div>
        </div>
    );
};

export default ProductSkeleton;
