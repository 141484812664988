import React, {useState, useEffect} from 'react';
import CurrentProductContent from '../../components/abtest/CurrentProduct';
import FamilyResults from '../../components/abtest/Family';
import {useParams} from 'react-router-dom';
import ComparisionSection from "../../components/abtest/ComparisionSection";
import {axiosPrivate} from "../../lib/api";
import TabButton from "../../components/UI/TabButton";
import Actions from "../../components/abtest/Actions";

const ABTestDetails = () => {
    const [abTestData, setAbTestData] = useState(null);
    const [loading, setLoading] = useState(true);  // Add loading state
    const [currentTab, setCurrentTab] = useState('current-product');
    const {abTestId} = useParams();

    const fetchAbtestDetails = async () => {
        try {
            setLoading(true);  // Set loading to true before the API request
            const response = await axiosPrivate.get(`abtests/api/get_abtest/${abTestId}`);
            setAbTestData(response.data);
        } catch (error) {
            console.error('Error fetching AB test details:', error);
        } finally {
            setLoading(false);  // Set loading to false after the API request completes
        }
    };

    useEffect(() => {
        fetchAbtestDetails();
    }, [abTestId]);


    if (loading) {
        // Display a loading spinner or message when data is being fetched
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-600"></div>
                <p className="ml-4 text-xl text-gray-600">Loading AB Test Details...</p>
            </div>
        );
    }

    if (!abTestData) return <p>Error loading data or no data found.</p>;
    const {
        abtest,
        abtest_traffic_data: abtestTrafficData,
        abtest_traffic_summary: abtestTrafficSummary,
        result_data: resultData,
        variants,
        can_extend: canExtend,
    } = abTestData;
    console.log(currentTab)
    return (
        <div className="p-8 max-w-7xl w-full mx-auto">
            {/* Page Title */}
            <h1 className="text-4xl font-extrabold text-blue-600 mb-10 text-center">{abtest.name}</h1>

            {/* Test and Product Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-white rounded-lg shadow-lg mb-12 p-6">
                {/* Test Details */}
                <div className="p-6 border-r border-gray-200">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Test Details</h2>
                    <div className="space-y-4 text-lg text-gray-600">
                        <p><strong>Hypothesis:</strong> <span className="text-gray-800">{abtest.hypothesis}</span></p>
                        <p><strong>Test Type:</strong> <span className="text-gray-800">{abtest.type}</span></p>
                        <p><strong>Start Date:</strong> <span className="text-gray-800">{abtest.start_date}</span></p>
                        <p><strong>End Date:</strong> <span className="text-gray-800">{abtest.end_date}</span></p>
                    </div>
                </div>

                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Product Details</h2>
                    <p className="text-lg text-gray-600 mb-4"><strong>Title:</strong> <span
                        className="text-gray-800">{abtest.product.item_name}</span></p>
                    <div className="grid grid-cols-3 gap-6 items-center text-gray-600">
                        <div className="text-center border p-4 rounded-lg">
                            <p className="font-semibold">SKU</p>
                            <p className="text-gray-800">{abtest.product.seller_sku}</p>
                        </div>
                        <div className="text-center border p-4 rounded-lg">
                            <p className="font-semibold">ASIN</p>
                            <p className="text-gray-800">{abtest.product.asin1}</p>
                        </div>
                        <div className="text-center border p-4 rounded-lg hover:bg-gray-100 transition duration-300">
                            <a href={`https://www.amazon.com/dp/${abtest.product.asin1}`} target="_blank"
                               rel="noreferrer">
                                <img
                                    src="https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png"
                                    alt="Amazon Link"
                                    className="w-10 h-10 mx-auto"
                                />
                            </a>
                            <p className="text-xs mt-2 text-gray-500">View on Amazon</p>
                        </div>
                    </div>
                </div>
            </div>

            <ComparisionSection abtest={abtest}/>

            <div className="mb-8">
                <nav className="flex border-b border-gray-200">
                    <TabButton
                        isActive={currentTab === 'current-product'}
                        label="Current Product"
                        onClick={() => setCurrentTab('current-product')}
                    />
                    <TabButton
                        isActive={currentTab === 'family'}
                        label="Variants Results"
                        onClick={() => setCurrentTab('family')}
                    />
                </nav>
            </div>
            {currentTab === 'current-product' && (
                <CurrentProductContent
                    abtestTrafficData={abtestTrafficData}
                    abtestTrafficSummary={abtestTrafficSummary}
                    resultData={resultData}
                    abTest={abtest}
                    isTabVisible={currentTab === 'current-product'}
                />)}
            {currentTab === 'family' && <FamilyResults variants={variants}/>}
            { canExtend && (<Actions
                abTestData={abTestData}
                setAbTestData={setAbTestData}
                abTestId={abTestId}
            /> )}
            <div className="mt-8">
                <a href="/abtests"
                   className="inline-block px-6 py-3 bg-gray-300 text-gray-800 font-semibold rounded-lg shadow-md hover:bg-gray-400"
                >
                    Back to AB Tests
                </a>
            </div>
        </div>
    );
};

export default ABTestDetails;
