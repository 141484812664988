import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { axiosPrivate } from "../../lib/api";
import LoadingSkeleton from "../../components/abtests/LoadingSkeleton";
import EmptyState from "../../components/abtests/EmptyState";
import TestList from "../../components/abtests/TestList";
import Controls from '../../components/abtests/Controls'; // Import the new Controls component

function ABTests() {
    const [abTestsData, setAbTestsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showExpired, setShowExpired] = useState('all'); // State to toggle between all, expired, and active tests
    const [searchQuery, setSearchQuery] = useState(''); // State for the search query
    const [sortBy, setSortBy] = useState('new_first'); // State for sorting criteria

    const fetchAbTests = async () => {
        try {
            const response = await axiosPrivate.get(`abtests/api/get_abtests/`);
            setAbTestsData(response.data);
        } catch (error) {
            console.error("Error fetching AB tests:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAbTests();
    }, []);

    // Apply search filter to the data
    const searchedTests = abTestsData.filter(test =>
        test.product.asin1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.hypothesis?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.product.attributes.item_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.product.seller_sku?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate the number of tests after the search
    const totalTestsCount = searchedTests.length;
    const activeTestsCount = searchedTests.filter(test => !test.is_expired).length;
    const expiredTestsCount = searchedTests.filter(test => test.is_expired).length;

    // Filter and sort the searched tests based on "All", "Active", "Expired", and sorting criteria
    const filteredTests = searchedTests
        .filter(test =>
            showExpired === 'all' ||
            test.is_expired === (showExpired === 'expired')
        )
        .sort((a, b) => {
            if (sortBy === 'new_last') {
                return new Date(a.start_date) - new Date(b.start_date);
            } else if (sortBy === 'new_first') {
                return new Date(b.start_date) - new Date(a.start_date);
            } else if (sortBy === 'test_type') {
                return a.type.localeCompare(b.type);
            } else {
                return 0; // Default: no sorting
            }
        });

    return (
        <div className="mt-6">
            <div className="flex justify-between align-center mb-6">
                <h2 className="text-2xl font-semibold mb-4">My Tests</h2>
                <Link to="/abtests/create">
                    <button
                        type="button"
                        className="btn-open-modal bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Create AB Test
                    </button>
                </Link>
            </div>

            {/* Use the new Controls component here */}
            <Controls
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                showExpired={showExpired}
                setShowExpired={setShowExpired}
                totalTestsCount={totalTestsCount}
                activeTestsCount={activeTestsCount}
                expiredTestsCount={expiredTestsCount}
                sortBy={sortBy}
                setSortBy={setSortBy}
            />

            {loading ? (
                <LoadingSkeleton />
            ) : (
                !filteredTests.length ? (
                    <EmptyState />
                ) : (
                    <TestList abTestsData={filteredTests} />
                )
            )}
        </div>
    );
}

export default ABTests;
