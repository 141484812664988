import React from "react";

const TestPrice = ({ selectedProduct, newPrice, setNewPrice, errors}) => {
    return (
        <div id="priceSection" className="mt-6">
            <label className="block text-lg font-medium text-gray-800">Selected Product Price</label>
            <p className="p-2 text-gray-700 bg-gray-100 rounded">{selectedProduct.price}</p>
            <input
                type="text"
                name="new_price"
                placeholder="Enter new price"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.newPrice && <div className="text-red-600 my-1">{errors.newPrice}</div>}
        </div>
    )
}

export default TestPrice;