import React from 'react';

function FilterButtons({ showExpired, setShowExpired, totalTestsCount, activeTestsCount, expiredTestsCount }) {
    return (
        <div className="flex items-center ml-4">
            <button
                onClick={() => setShowExpired('all')}
                className={`px-4 py-2 ${showExpired === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded-l`}
            >
                All ({totalTestsCount})
            </button>
            <button
                onClick={() => setShowExpired('active')}
                className={`px-4 py-2 ${showExpired === 'active' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
                Running ({activeTestsCount})
            </button>
            <button
                onClick={() => setShowExpired('expired')}
                className={`px-4 py-2 rounded-r ${showExpired === 'expired' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
                Complete ({expiredTestsCount})
            </button>
        </div>
    );
}

export default FilterButtons;
