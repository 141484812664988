import React from "react";
import TestCard from "./TestCard";

function TestList({abTestsData}) {
    return (
        <div className="space-y-6">
            {abTestsData.map((abTestData, index) => (
                <TestCard key={abTestData.id} abTestData={abTestData}/>
            ))}
        </div>
    );
}
export default TestList;
