// TabButton.js
import React from 'react';

const TabButton = ({ isActive, label, onClick }) => (
    <button
        className={`tab-button py-4 px-6 font-medium ${isActive ? 'border-b-2 border-solid border-blue-500 text-gray-900' : 'border-transparent text-gray-500'}`}
        onClick={onClick}
    >
        {label}
    </button>
);

export default TabButton;
