import React from 'react';
import abTestResult from "./AbTestResult";
import AbTestResult from "./AbTestResult";
import MetricBox from "./MetricBox";
import { CiShoppingCart, CiDollar } from "react-icons/ci";
import { FaEye, FaChartBar, FaShoppingCart, FaDollarSign } from "react-icons/fa";

import Chart from "./Chart"; // Import the new Chart component

const CurrentProductContent = ({abtestTrafficSummary, abtestTrafficData, resultData, abTest, isTabVisible}) => {
    console.log("Hello")
    const {
        total_orders: totalOrders,
        total_sales: totalSales,
        total_page_views: totalPageViews,
        conversion
    } = abtestTrafficSummary;
    return (
        <div id="current-product-content" className="tab-content">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
                <MetricBox
                    title="Total Orders"
                    value={totalOrders}
                    icon=<FaShoppingCart />
                    color="green"
                />
                <MetricBox
                    title="Total Sales"
                    value={`$${totalSales}`}
                    icon=<FaDollarSign />
                    color="blue"
                />
                <MetricBox
                    title="Total Page Views"
                    value={totalPageViews}
                    icon=<FaEye />
                    color="orange"
                />
                <MetricBox
                    title="Conversion Rate"
                    value={`${conversion}%`}
                    icon=<FaChartBar />
                    color="purple"
                />
            </div>
            <div id="chart">
                <div className="container mx-auto py-8">
                    <h2 className="text-2xl font-bold mb-4">Page Views, Orders, and Sales Over Time</h2>
                    <Chart abtestTrafficData={abtestTrafficData} isTabVisible={isTabVisible}/>
                </div>
            </div>
            {resultData && <AbTestResult resultData={resultData} abtest={abTest}/>}
        </div>
    );
};

export default CurrentProductContent;
