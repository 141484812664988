import React from 'react';

const TestTitle = ({selectedProduct, error, newTitle, setNewTitle}) => {
    return (
        <div id="titleSection" className="mt-6">
            <label className="block text-lg font-medium text-gray-800">Current Product Title</label>
            <p className="p-2 text-gray-700 bg-gray-100 rounded mb-2">{selectedProduct.item_name}</p>
            <label className="block text-lg font-medium text-gray-800">New Product Title</label>
            <textarea
                name="new_title"
                placeholder="Enter new title"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {error && <div className="text-red-600 my-1">{error}</div>}
        </div>
    )
}

export default TestTitle;
