// components/header/NavItem.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ to, children }) => {
    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                return isActive
                    ? "text-white bg-blue-600 shadow-sm px-3 py-2 rounded-md text-sm font-medium transform scale-105 transition-all duration-200"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-all duration-200";
            }}
        >
            {children}
        </NavLink>
    );
};

export default NavItem;
