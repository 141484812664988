// src/pages/SupportPage.js
import {FiPhone, FiMail} from "react-icons/fi";
import {FaQuestionCircle} from "react-icons/fa";
import {useState} from "react";
import axiosApi from "../../lib/api";

const SupportPage = () => {
    const [feedback, setFeedback] = useState("");
    const [email, setEmail] = useState('')
    const [response, setResponse] = useState(null);

    const handleSubmit = async (e) => {
        console.log("Hello")
        e.preventDefault();
        try{
            const response = await axiosApi.post('/user/api/support/', {
                email,
                feedback,
            });
            setResponse('Your message has been sent!');
            setEmail('');
            setFeedback('');
        } catch (error) {
            setResponse('There was an error sending your message. Please try again later.');
        }
    };

    return (
        <div className="max-w-2xl mx-auto mt-10 p-8 bg-white rounded-lg shadow-md">
            <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Support</h1>

            {/* Contact Information */}
            <section className="mb-10">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Contact Us</h2>
                <div className="flex items-center space-x-3 bg-gray-100 p-4 rounded-md">
                    <FiMail className="text-blue-500" size={24}/>
                    <span className="text-gray-700 text-sm">Email: <a href="mailto:support@bestsr.ai"
                                                                      className="text-blue-600 hover:underline">support@bestsr.ai</a></span>
                </div>
            </section>

            {/* Helpful Links */}
            <section className="mb-10">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Helpful Links</h2>
                <div className="space-y-3">
                    <a href="/faqs" className="flex items-center text-blue-600 hover:underline text-sm">
                        <FaQuestionCircle className="mr-2 text-blue-500"/>
                        Frequently Asked Questions
                    </a>
                </div>
            </section>

            {/* Feedback Form */}
            <section>
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Send Us Feedback</h2>
                <form onSubmit={handleSubmit} className="space-y-5">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email"
                        className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150"
                    />
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Let us know your thoughts or issues..."
                        className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150"
                        rows="5"
                    />
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        Submit Feedback
                    </button>
                </form>
                {response && (
                    <p className={`mt-4 text-center font-medium ${response.includes('error') ? 'text-red-600' : 'text-green-600'}`}>
                        {response}
                    </p>
                )}
            </section>
        </div>

    );
};

export default SupportPage;
