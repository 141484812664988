import {Link} from "react-router-dom";
import React from "react";
import ProductInfoTable from "./ProductInfoTable";
import {FiCheckCircle, FiAlertCircle, FiInfo} from 'react-icons/fi';

function TestCard({abTestData}) {
    const {
        name, hypothesis, is_expired,
        days_running, item_name, product, result_type: resultType,
        start_date: startDate, end_date: endDate, id, type, probability
    } = abTestData;
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
            <div className="p-4 flex justify-between items-center">
                <div className="w-3/4">
                    <h3 className="text-xl font-semibold text-gray-900">{name}</h3>
                    <p className="text-sm font-light text-gray-500">{hypothesis}</p>
                    <p className="text-gray-700 mb-2 mt-2">
                        <i className="fas fa-box-open text-gray-500"></i>
                        <span className="font-medium">Product: </span>
                        <span className="tooltip">{item_name}</span>
                    </p>
                </div>
                <div className="flex items-center space-x-2">
                    {is_expired ? (
                        <>
                            {resultType ? (
                                <span
                                    className={`flex items-center space-x-2 px-4 py-2 rounded-lg text-lg font-semibold shadow-md ${
                                        resultType === 'positive'
                                            ? 'text-green-800 bg-green-100 border border-green-300'
                                            : resultType === 'negative'
                                                ? 'text-red-800 bg-red-100 border border-red-300'
                                                : 'text-blue-800 bg-blue-100 border border-blue-300'
                                    }`}
                                >
                                {resultType === 'positive' && <FiCheckCircle className="text-green-600 text-2xl"/>}
                                    {resultType === 'negative' && <FiAlertCircle className="text-red-600 text-2xl"/>}
                                    {resultType === 'neutral' && <FiInfo className="text-blue-600 text-2xl"/>}
                                    <span>{probability}%</span>
                                </span>
                            ) : (
                                <span
                                    className="inline-block bg-blue-100 text-gray-600 p-3 rounded-lg text-sm"
                                >
                                  Result Available on: {new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 2)).toLocaleDateString()}
                                </span>
                            )}
                        </>
                    ) : days_running === 0 ? (
                        <span className="inline-block bg-green-100 text-green-600 py-1 px-2 rounded-lg text-xs">Started Today</span>
                    ) : days_running < 0 ? (
                        <span className="inline-block bg-blue-100 text-blue-600 py-1 px-2 rounded-lg text-xs">Not Started Yet</span>
                    ) : (
                        <span className="inline-block bg-green-100 text-green-600 py-1 px-2 rounded-lg text-xs">
              Running for {days_running} days
            </span>
                    )}
                </div>
            </div>

            <ProductInfoTable product={product} startDate={startDate} endDate={endDate}
                              type={type}/>

            <div className="bg-gray-50 p-4 border-t border-gray-200 flex justify-between items-center">
                <Link to={`/abtests/${id}`} className="text-gray-800 hover:text-blue-400">
                    <i className="fas fa-info-circle mr-2"></i>View Details
                </Link>
            </div>
        </div>
    );
}

export default TestCard;
