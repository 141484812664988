import React from 'react';

import Chart from "../UI/Chart";

const ChartTab = ({chartData}) => {
    const {labels, sales_data: salesData, orders_data: ordersData, pages_views_data:pageViewsData} = chartData
    const chartValues = [
        {
            label: 'Sales',
            data: salesData,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        },
        {
            label: 'Orders',
            data: ordersData,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
        },
        {
            label: 'Page Views',
            data: pageViewsData,
            borderColor: 'rgb(54, 162, 235)',
            tension: 0.1
        }
    ]
    return (
        <Chart
            chartData={chartData}
            labels={labels}
            chartValues={chartValues}
        />
    )
};

export default ChartTab;

