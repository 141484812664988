// components/header/Header.js
import React, {useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {Link} from 'react-router-dom';
import {UserContext} from "../../context/UserContext";
import axiosApi from "../../lib/api";
import NavItem from './NavItem';
import Toast from "../UI/Toast"; // Import NavItem component

function Header({children}) {
    const {user, setUser} = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            console.log('Handling Logout');
            const response = await axiosApi.post('user/logout/');
            if (response.status === 204) {
                console.log('Logout successful');
                setUser(null);
                navigate('/');
            }
        } catch (error) {
            console.error('Error during logout:', error);
            throw new Error('Logout failed');
        }
    };

    return (
        <div className="bg-gray-100">
            <nav className="bg-gray-800 p-4">
                <div className="container mx-auto flex justify-between items-center py-4 px-6">
                    <Link to="/" className="text-white text-xl font-semibold">
                        <img
                            src="https://res.cloudinary.com/dtqxwjmwn/image/upload/v1731120992/bestsr.ai/bestsrai-white.png"
                            alt="BestSRAI Logo"
                            className="h-16 w-auto"
                        />
                    </Link>

                    {user && <div className="text-white text-md">Hello, {user.user}</div>}
                    <ul className="flex space-x-4 items-center">
                        {user ? (
                            <>
                                <li>
                                    <NavItem to="/products">Products</NavItem>
                                </li>
                                <li>
                                    <NavItem to="/abtests">A/B Test</NavItem>
                                </li>

                                <li>
                                    <div
                                        onClick={handleLogout}
                                        className="cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-start"
                                    >
                                        Log Out
                                    </div>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <NavItem to="/login">Log In</NavItem>
                                </li>
                                <li>
                                    <NavItem to="/faqs">FAQs</NavItem>
                                </li>
                                <li>
                                    <NavItem to="/support">Support</NavItem>
                                </li>
                            </>

                        )}
                    </ul>
                </div>
            </nav>
            <div className="min-h-screen">
                {children}
            </div>

            <Toast/>
        </div>
    );
}

export default Header;
