import React from "react";

const TestBulletPoints = ({ bulletPoints, setBulletPoints, error}) => {
     const handleBulletPointChange = (index, value) => {
        const updatedBulletPoints = [...bulletPoints];
        updatedBulletPoints[index].value = value;
        setBulletPoints(updatedBulletPoints);
    };

    const addBulletPoint = () => {
        setBulletPoints([...bulletPoints, {value: ''}]);
    };

    const removeBulletPoint = (index) => {
        const updatedBulletPoints = bulletPoints.filter((_, i) => i !== index);
        setBulletPoints(updatedBulletPoints);
    };
    return (
        <div id="bulletPointsSection" className="mt-6">
            <label className="block text-lg font-medium text-gray-800">Bullet Points</label>
            {bulletPoints.map((bulletPoint, index) => (
                <div className="flex items-center mb-2" key={index}>
                <textarea
                    name={`bullet_points[${index}]`}
                    value={bulletPoint.value}
                    onChange={(e) => handleBulletPointChange(index, e.target.value)}
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                    <button
                        type="button"
                        onClick={() => removeBulletPoint(index)}
                        className="ml-2 p-2 text-red-600 hover:bg-red-100 rounded"
                    >
                        Delete
                    </button>
                </div>
            ))}
            {error && <div className="text-red-600 my-1">{error}</div>}
            <button
                type="button"
                onClick={addBulletPoint}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
                Add More Bullet Points
            </button>
        </div>
    )
}
export default TestBulletPoints;